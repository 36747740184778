"use client"
import "../globals.css"
import { Header } from "../components/header"
import { Authenticated } from "@/shared/lib/auth/authenticated"
import { PropsWithChildren } from "react"
import { EmsNotificationManager } from "@/shared/components/ems-notification/ems-notification-manager"
import { NotificationItem } from "@/shared/lib/redux/slices/notifications"
import { selectAllNotifications, useSelector } from "@/lib/redux"

const DEMO_DISABLE_AUTH = process.env.DEMO_DISABLE_AUTH === "true"

export default function AuthenticatedLayout({ children }: PropsWithChildren) {
  const notifications: NotificationItem[] = useSelector(selectAllNotifications)

  const content = (
    // trick to change the keyboard taborder so that first all tabbable content elements can be focused and after that the items in the page header
    <div className="flex flex-col-reverse h-full">
      <main className="grow">
        {children}
        <EmsNotificationManager notifications={notifications} data-testid={"notification-manager"} />
      </main>
      <Header />
    </div>
  )
  return DEMO_DISABLE_AUTH ? content : <Authenticated>{content}</Authenticated>
}
