import { addNotification, removeNotification } from "../notifications"
import { api } from "@/lib/redux/slices/api/api-slice"
import { CONNECTION_ERROR_NOTIFICATION_ID, CONNECTION_CHECK_INTERVAL } from "@/shared/lib/dataloading/fetch-util"
import { DateTime } from "luxon"

export const DEFAULT_CONNECTION_QUERY_OPTIONS = {
  pollingInterval: CONNECTION_CHECK_INTERVAL,
  refetchOnReconnect: true,
}

export const connectionApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    checkConnection: build.query<void, void>({
      query: () => "/system/ping",
      providesTags: ["connection"],
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled
          dispatch(removeNotification({ itemOrId: CONNECTION_ERROR_NOTIFICATION_ID }))

          /* todo: rtk query tags also need to be invalidated here, otherwise api calls will only be done after reestablishing connection */
        } catch (e: any) {
          dispatch(
            addNotification({
              item: {
                id: CONNECTION_ERROR_NOTIFICATION_ID,
                notificationType: "global_error",
                messageKey: e?.error?.status === 500 ? "connectionErrorInternal" : "connectionError",
                timestampAdded: DateTime.now().toISO(),
              },
            }),
          )
        }
      },
    }),
  }),
})

export const { useCheckConnectionQuery } = connectionApi
