"use client"
import { EmsPageHeader } from "@/shared/components/ems-page-header/ems-page-header"
import { EmsIconButton } from "@/shared/components/ems-icon-button/ems-icon-button"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import HelpIcon from "@mui/icons-material/Help"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import LogoutIcon from "@mui/icons-material/Logout"
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react"
import { useTranslations } from "next-intl"
import { DEFAULT_CONNECTION_QUERY_OPTIONS, useCheckConnectionQuery, useSelector } from "@/lib/redux"
import { selectIsGlobalError } from "@/lib/redux/slices"
import { getEnv } from "@/lib/get-env"
import { AuthContextProps, useAuth } from "react-oidc-context"
import { useEffect } from "react"
import { selectIsConnectionError } from "@/lib/redux/slices/connection-check/selectors"
import { handleInvalidGrant, triggerSignoutRedirect, triggerSilentSignIn } from "@/shared/lib/auth/utils"

const USER_MANUAL_KEY = "user-manual"
const ACCOUNT_SETTINGS_KEY = "account-settings"

const userManualSource: string = getEnv("EMS_FRONTEND_USER_MANUAL_SOURCE")
const accountSettingsSource: string = getEnv("EMS_FRONTEND_ACCOUNT_SETTINGS_SOURCE")
const disabledKeys: string[] = []

if (!userManualSource) {
  disabledKeys.push(USER_MANUAL_KEY)
}

if (!accountSettingsSource) {
  disabledKeys.push(ACCOUNT_SETTINGS_KEY)
}

export function Header() {
  const t = useTranslations("EmsPageHeader")
  const isGlobalError: boolean = useSelector(selectIsGlobalError)
  const isConnectionError: boolean = useSelector(selectIsConnectionError)
  const authContextProps: AuthContextProps = useAuth()

  useCheckConnectionQuery(undefined, {
    ...DEFAULT_CONNECTION_QUERY_OPTIONS,
    skip: getEnv("EMS_FRONTEND_DISABLE_CONNECTION_CHECK") === "true",
  })

  /**
   *  Used to trigger silent sign in right before access token expires
   *  will set state according to silent sign-in being successful or not
   * @param authContextProps
   */

  const handleAccessTokenExpired = async (authContextProps: AuthContextProps) => {
    authContextProps.events.addAccessTokenExpired(async () => {
      await triggerSilentSignIn(authContextProps)
    })
  }

  /**
   *  Used to trigger silent sign in after connection was re-established and token is still valid
   * @param authContextProps
   * @param isConnectionError
   */
  const handleConnectionReestablished = async (authContextProps: AuthContextProps, isConnectionError: boolean) => {
    if (authContextProps.error !== undefined && !isConnectionError) {
      await triggerSilentSignIn(authContextProps)
    }
  }

  useEffect(() => {
    void handleConnectionReestablished(authContextProps, isConnectionError)
    return () => {
      if (!isConnectionError) {
        authContextProps.events.removeSilentRenewError(() => void triggerSilentSignIn(authContextProps))
      }
    }
    // Removed other dependencies because we only want to trigger the token request when connection was restored
    // eslint-disable-next-line
  }, [isConnectionError])

  useEffect(() => {
    handleInvalidGrant(authContextProps)
    // Removed other dependencies because we only want to trigger the sign-out when the refresh token is definitely expired
    // eslint-disable-next-line
  }, [authContextProps.error])

  useEffect(() => {
    void handleAccessTokenExpired(authContextProps)
    // Removed other dependencies because we only want to update the event listener once
    // eslint-disable-next-line
  }, [authContextProps.events])

  return (
    <EmsPageHeader appName={t("app")}>
      <Dropdown
        offset={4}
        classNames={{
          base: ["bg-ems-surface", "border", "border-ems-line-medium", "p-0"],
          content: ["rounded-none", "p-0", "shadow-none"],
        }}
        placement="bottom-end"
      >
        <DropdownTrigger>
          <EmsIconButton disabled={isGlobalError}>
            <HelpIcon />
          </EmsIconButton>
        </DropdownTrigger>
        <DropdownMenu
          aria-label={t("infoMenuAria")}
          disabledKeys={disabledKeys}
          classNames={{
            base: ["px-0", "py-4", "w-60"],
            list: ["gap-1"],
          }}
          itemClasses={{
            base: [
              "rounded-none",
              "text-ems-on-surface",
              "data-[hover=true]:bg-ems-surface-hovered",
              "data-[pressed=true]:bg-ems-surface-pressed",
              "pl-10",
              "h-10",
              "flex",
              "gap-1",
            ],
          }}
        >
          <DropdownItem key={USER_MANUAL_KEY} target="_blank" aria-label={t("userManualAria")} href={userManualSource}>
            {t("userManual")} <OpenInNewIcon className="m-2" />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {/*<EmsIconButton disabled={isGlobalError}>*/}
      {/*  <SettingsIcon />*/}
      {/*</EmsIconButton>*/}
      <Dropdown
        isDisabled={isGlobalError}
        offset={4}
        classNames={{
          base: ["bg-ems-surface", "border", "border-ems-line-medium", "p-0"],
          content: ["rounded-none", "p-0", "shadow-none"],
        }}
        placement="bottom-end"
      >
        <DropdownTrigger>
          <EmsIconButton disabled={isGlobalError}>
            <AccountCircleIcon />
          </EmsIconButton>
        </DropdownTrigger>
        <DropdownMenu
          aria-label={t("settingsMenuAria")}
          disabledKeys={disabledKeys}
          classNames={{
            base: ["px-0", "py-4", "w-60"],
            list: ["gap-1"],
          }}
          itemClasses={{
            base: [
              "rounded-none",
              "text-ems-on-surface",
              "data-[hover=true]:bg-ems-surface-hovered",
              "data-[pressed=true]:bg-ems-surface-pressed",
              "pl-10",
              "h-10",
              "flex",
              "gap-1",
            ],
          }}
        >
          <DropdownItem
            key={ACCOUNT_SETTINGS_KEY}
            target="_blank"
            aria-label={t("accountSettingsAria")}
            href={accountSettingsSource}
          >
            {t("accountSettings")}
          </DropdownItem>
          <DropdownItem
            key="logout"
            aria-label={t("logoutAria")}
            onClick={() => triggerSignoutRedirect(authContextProps)}
          >
            {t("logout")} <LogoutIcon className="m-2" />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </EmsPageHeader>
  )
}
