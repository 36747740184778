"use client"
import React, { ReactElement } from "react"
import { Card, CardBody } from "@nextui-org/react"
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined"
import { CommonComponentProps } from "@/shared/lib/component-types"
import { removeNotification } from "@/shared/lib/redux/slices/notifications"
import { useTranslations } from "next-intl"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import { EmsIconButton } from "@/shared/components/ems-icon-button/ems-icon-button"
import CloseIcon from "@mui/icons-material/Close"
import { useDispatch } from "react-redux"

export type EmsNotificationMessageProps = CommonComponentProps & {
  id: string
  messageType: string
  messageKey?: string
  translate?: boolean
}

/**
 * Renders an icon used inside each NotificationMessage
 * @component
 * @param {string} messageType the notification type, used to determine the icon style
 * @returns {ReactElement} the rendered NotificationIcon
 */

function NotificationMessageIcon({ messageType }: { messageType: string }): ReactElement {
  switch (messageType) {
    case "error":
    case "global_error":
      return <DangerousOutlinedIcon className="w-6 h-6" />
    case "success":
      return <TaskAltIcon className="w-6 h-6 text-ems-status-success" />
    default:
      return <DangerousOutlinedIcon className="w-6 h-6 text-ems-status-error" />
  }
}

/**
 * Renders a NotificationMessage inside a NotificationManager
 * @component
 * @param {string} id identification which can be used to remove the notification later on
 * @param {string} messageType tells if the notification should be rendered as info|error|etc. (this will result in a different style)
 * @param {string | undefined} messageKey the content of the notification which will either be passed as raw text or as a key for the internationalization file
 * @param {boolean | undefined} translate tells if the messageKey should be used as a key for translation or passed as string, defaults to true
 * @param {string | undefined} dataTestid reference which can be used in testing
 * @returns {ReactElement} the rendered NotificationMessage
 */

export function EmsNotificationMessage({
  id,
  messageType,
  messageKey,
  translate = true,
  "data-testid": dataTestId,
}: EmsNotificationMessageProps): ReactElement {
  const t = useTranslations("EmsNotificationMessage")
  const dispatch = useDispatch()

  function closePressed() {
    dispatch(removeNotification({ itemOrId: id }))
  }

  return (
    <Card
      className={`w-[28.125rem] bg-ems-surface border border-ems-line-fair border-l-4 min-h-[56px] h-auto p-0
        ${messageType === "success" ? "border-l-ems-status-success" : "border-l-ems-status-error"}
        shadow-[-2px_3px_6px_0_rgba(0,0,0,0.11),1px_-1px_6px_0_rgba(0,0,0,0.07)]`}
      radius="none"
      shadow="none"
      data-testid={dataTestId}
    >
      <CardBody className="flex justify-between flex-row p-2 pl-1">
        <div className="flex flex-row gap-2">
          <div className="flex justify-center items-center w-10 h-10 shrink-0">
            <NotificationMessageIcon messageType={messageType}></NotificationMessageIcon>
          </div>
          <div
            className="flex flex-col py-1.5 text-ems-on-surface whitespace-pre-line"
            data-testid={dataTestId + "-text"}
          >
            <p className="text-body-full-length">{translate ? t(messageKey) : messageKey}</p>
          </div>
        </div>
        {messageType !== "global_error" ? (
          <EmsIconButton onPress={closePressed}>
            <CloseIcon className="w-6 h-6" />
          </EmsIconButton>
        ) : (
          ""
        )}
      </CardBody>
    </Card>
  )
}
