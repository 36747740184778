import type { AuthContextProps } from "react-oidc-context"
import { User } from "oidc-client-ts"
import { getEnvShared } from "@/shared/lib/get-env"

// See: https://github.com/authts/react-oidc-context?tab=readme-ov-file#call-a-protected-api
const SESSION_STORAGE_OIDC_KEY = `oidc.user:${getEnvShared("EMS_FRONTEND_OAUTH_AUTHORITY")}:${getEnvShared("EMS_FRONTEND_OAUTH_CLIENT_ID")}`

/**
 * Trigger silent refresh of access token if the session (refresh token) is not expired
 * @param authContextProps
 */
export const triggerSilentSignIn = async (authContextProps: AuthContextProps): Promise<User | null> => {
  return await authContextProps.signinSilent()
}

/**
 * Trigger sign out redirect.
 * @param authContextProps
 */
export const triggerSignoutRedirect = async (authContextProps: AuthContextProps) => {
  await authContextProps.signoutRedirect()
}

/**
 *  Used to trigger signOutRedirect when refresh token expires
 *  'invalid_grant' is the error identification received on refresh token expiration
 * @param authContextProps
 */
export function handleInvalidGrant(authContextProps: AuthContextProps) {
  if (authContextProps.error && "error" in authContextProps.error && authContextProps.error.error === "invalid_grant") {
    void triggerSignoutRedirect(authContextProps)
  }
}

/**
 * used to get a oidc user object from local storage if possible
 * @returns a User if it exists and null otherwise
 */

export function getUser() {
  const oidcStorage = localStorage.getItem(SESSION_STORAGE_OIDC_KEY)
  if (!oidcStorage) {
    return null
  }

  return User.fromStorageString(oidcStorage)
}
