import { Action, createSlice, PayloadAction, ThunkDispatch } from "@reduxjs/toolkit"
import { CONNECTION_ERROR_NOTIFICATION_ID } from "@/shared/lib/dataloading/fetch-util"

export type IsoDateString = string

export type NotificationItem = {
  id: string
  notificationType: NotificationType | string
  messageKey: string
  timestampAdded: IsoDateString
  timeout?: number
  translate?: boolean
  title?: string
}

export type NotificationType = "success" | NotificationErrorType

export type NotificationErrorType = "error" | "global_error"

export type NotificationsState = {
  notifications: Array<NotificationItem>
}

export type ReduxStateWithNotification = {
  notifications: NotificationsState
}

const initialState: NotificationsState = {
  notifications: [],
}

export type AddNotificationPayload = { item: NotificationItem }
export type RemoveNotificationPayload = { itemOrId: NotificationItem | string }

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<AddNotificationPayload>) => {
      const newNotification: NotificationItem = action.payload.item
      const isAddingConnectionErrorNotification = newNotification.id === CONNECTION_ERROR_NOTIFICATION_ID
      const isConnectionNotificationAlreadyPresent = state.notifications.some(
        (item) => item.id === CONNECTION_ERROR_NOTIFICATION_ID,
      )

      if (isAddingConnectionErrorNotification) {
        state.notifications = [newNotification]
      } else if (
        !isConnectionNotificationAlreadyPresent &&
        !state.notifications.find((item) => item.id === newNotification.id)
      ) {
        state.notifications.push(newNotification)
      }
    },
    removeNotification: (state, action: PayloadAction<RemoveNotificationPayload>) => {
      const itemOrId: string | NotificationItem = action.payload.itemOrId
      const id: string = typeof itemOrId === "string" ? itemOrId : (itemOrId as NotificationItem).id

      state.notifications = state.notifications.filter((item) => item.id !== id)
    },
    clearNotifications: (state) => {
      state.notifications = []
    },
  },
})

export function addNotification(action: { item: NotificationItem }) {
  return (dispatch: ThunkDispatch<ReduxStateWithNotification, void, Action>) => {
    const item = action.item

    dispatch(notificationsSlice.actions.addNotification({ item }))

    if (item.timeout) {
      setTimeout(() => {
        dispatch(notificationsSlice.actions.removeNotification({ itemOrId: item }))
      }, item.timeout)
    }
  }
}

export const { removeNotification, clearNotifications } = notificationsSlice.actions
