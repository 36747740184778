"use client"
import { PropsWithChildren, useEffect, useState } from "react"
import { hasAuthParams, useAuth } from "react-oidc-context"
import { log } from "../logging/logger"

export function Authenticated({ children }: PropsWithChildren) {
  const { isAuthenticated, signinRedirect, activeNavigator, isLoading } = useAuth()
  const [hasTriedSignin, setHasTriedSignin] = useState(false)

  useEffect(() => {
    if (!hasAuthParams() && !isAuthenticated && !activeNavigator && !isLoading && !hasTriedSignin) {
      // delay the re-login to prevent hick-ups from not-yet closed sessions
      // Without this, we noticed a behavior were the user was directly re-logged in without having to enter the credentials
      setTimeout(() => {
        log("Will trigger signinRedirect")
        void signinRedirect()
        setHasTriedSignin(true)
      }, 100)
    }
  }, [activeNavigator, hasTriedSignin, isAuthenticated, isLoading, signinRedirect])

  if (isAuthenticated) {
    return <>{children}</>
  } else {
    return null
  }
}
